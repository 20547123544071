










































































































































import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import {validationMixin} from "vuelidate";
import {formatDate, getDateByString, getMonthName} from "@/lib/date";
import XButton from "../SimpleButton.vue";
import {datePickerFormAsSingleField} from "@/assets/scripts/form-fields/main";

const {values, attrs, validations, errors} = datePickerFormAsSingleField;

@Component({
  components: {XButton},
  mixins: [validationMixin],
  computed: errors,
  validations,
})
class DropdownWithDatePickerWithoutRange extends Vue {
  [x: string]: any;

  @Prop({default: ""}) readonly modClass!: string;
  @Prop({default: true}) readonly showMdiChevronDown!: boolean;
  @Prop({default: ""}) readonly min!: string;
  @Prop({default: "month"}) readonly datePickerType!: string;
  @Prop({default: true}) readonly formNeeded!: string;
  @Prop({default: ""}) value!: string;

  values = values;
  allFieldAttrs = attrs;

  activatorText = "";
  date = "";

  @Emit()
  private emitPickEvt() {
    this.$emit("update:date", new Date(this.date).toISOString().slice(0, 19));
  }

  @Emit("click")
  public propagateCustomEvt(evtName: string) {
    this.$emit(evtName, evtName);
  }

  @Emit()
  emitUpdateDropdown(show: boolean) {
    this.$emit("update:show", show);
  }

  @Watch("min")
  public minChanged() {
    this.defaultStateHook();
    this.emitPickEvt();
  }

  private defaultStateHook() {
    const date = this.min ? new Date(this.min) : new Date();
    const humanDate = this.datePickerType == 'month' ? formatDate(date, "monthAndYear") : formatDate(date, "full");
    const isoDate = this.datePickerType == 'month'? date.toISOString().slice(0, 7) : date.toISOString();
    this.values.date = humanDate;
    this.date = isoDate;
    this.activatorText = humanDate;
  }

  public created() {
    this.defaultStateHook();
    this.emitPickEvt();
    if (this.value === "") {
      this.value = this.date;
    }
  }

  public formatMonth(date: string): string {
    return getMonthName(date, true).toUpperCase();
  }

  public onMonthClick(date: string) {
    this.values.date = formatDate(date, "monthAndYear");
  }

  public onDayClick(date: string) {
    this.values.date = formatDate(date, "full");
  }

  public applyDateChanges() {
    const {date} = this.values;

    this.date = getDateByString(date);
    this.activatorText = date;

    this.emitPickEvt();
  }

  public resetDateChanges() {
    this.defaultStateHook();
    this.$v.$reset();
  }

  public getErrors(fieldName: string): string | string[] {
    return this[`${fieldName}Errors`];
  }
}

export default DropdownWithDatePickerWithoutRange;
